import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/404.styles';
import NotFoundImage from '../images/404.png';
import { Link } from 'gatsby';

const StyledNotFoundPage = styled.div`
  text-align: center;
  margin: 40px 10px;

  img {
    margin: auto;
    max-width: 100%;
  }

  p {
    font-size: 20px;
    margin: 0;
  }

  a {
    font-size: 20px;
    color: #00ccff;
    text-decoration: none;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NotFoundPage = props => {
  if (!props.siteTitle.includes('-es')) {
    return (
      <StyledNotFoundPage>
        <img src={NotFoundImage} alt="Page Not Found" />
        <p>Food not found.</p>
        <p>
          Satisfy your hunger at our <Link to={`/`}>homepage.</Link>
        </p>
      </StyledNotFoundPage>
    );
  } else {
    return (
      <StyledNotFoundPage>
        <img src={NotFoundImage} alt="Page Not Found" />
        <p>No se encontró la comida.</p>
        <p>
          Satisfaga su hambre en nuestra página de <Link to={`/`}>inicio.</Link>
        </p>
      </StyledNotFoundPage>
    );
  }
};

export default NotFoundPage;
